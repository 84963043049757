<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent
        :button="drawerButton"
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        @save="requestSave"></DrawerComponent>
      <!-- 弹窗商品列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="添加渠道费用"
          size="80%"
          :visible.sync="drawerShowProduct"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchProduct"
                            :templateData="tableDataProduct"
                            :table-loading="tableLoadingProduct"
                            :searchConfig="searchConfigProduct"
                            :table-config="tableConfigProduct"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowProduct = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 客户列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择客户"
          size="80%"
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table" >
            <TableComponent @searchData="searchCustomer"
                            :pagination="tablePaginationCustomer"
                            :templateData="tableDataCustomer"
                            :table-loading="tableLoadingCustomer"
                            :searchConfig="searchConfigCustomer"
                            :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowCustomer = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>

    <DrawerComponent
      v-if="dmsId"
      :button="drawerButton"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      @save="requestSave"></DrawerComponent>
  </div>

</template>

<script>
import { template } from 'xe-utils';
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  props: ['dmsId', 'dmsCode'],
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      id: '',
      code: '',
      loading: false,
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: (data) => {
              this.requestSave(data, 'Y');
            },
          },
        ],
      },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { temporaryFlag: 'N' },
          formConfig: { // 弹窗表单配置
            items: [
              [{
                label: '申请编码',
                key: 'channelApplyCode',
                show: false,
              }, {
                label: '申请名称',
                key: 'channelApplyName',
              }, {
                label: '客户名称',
                key: 'custName',
                valueType: 'iconInput',
                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchCustomer({});
                },
              }, {
                label: '公司主体',
                key: 'companyCode',
                valueType: 'select',
                path: 'companyPageList',
                options: {
                  label: 'companyName',
                  value: 'companyCode',
                },
                fn: (value, formData, ref) => {
                  this.$nextTick(() => {
                    this.setFormData(ref[0].selectedLabel, 'companyName');
                    this.deleteProduct('all', 1);
                  });
                },
              }, {
                label: '支付方式',
                key: 'payType',
                valueType: 'select',
                path: 'code?dictTypeCode=channel_pool_type',
                fn: (val) => {
                  if (val === '1') {
                    this.setFormItemValue(['applyCash'], 'show', true);
                  } else {
                    this.setFormItemValue(['applyCash'], 'show', false);
                  }
                },
              },
              {
                label: '控制类型',
                key: 'controlType',
                valueType: 'select',
                path: 'code?dictTypeCode=dms_control_type',
                fn: (val) => {},
              },
              {
                label: '是否开票',
                key: 'isSupplyBill',
                valueType: 'select',
                path: 'code?dictTypeCode=yesOrNo',
                fn: (val) => {},
              },
              {
                label: '申请金额',
                key: 'applyCash',
                disabled: true,
                show: false,
              }],
            ],
          },
          rules: {
            companyCode: [{ required: true, message: '请选择公司主体', trigger: 'change' }],
            custName: [{ required: true, message: '请选择客户', trigger: 'change' }],
            controlType: [{ required: true, message: '请选择控制类型', trigger: 'change' }],
            isSupplyBill: [{ required: true, message: '请选择是否开票', trigger: 'change' }],
          },
        }, {
          title: '费用清单',
          type: 'table',
          tableConfig: {
            disabled: false,
            rows: [
              {
                label: '创建时间',
                prop: 'createDateAll',
              }, {
                label: '渠道费用名称',
                prop: 'channelFeeName',
              }, {
                label: '金额',
                prop: 'channelCash',
              }, {
                label: '商品名称',
                prop: 'productName',
              }, {
                label: '商品编码',
                prop: 'productCode',
              },
              {
                label: '需货单号',
                prop: 'orderCode',
              },
              {
                label: '数量',
                prop: 'channelNum',
              }, {
                label: '生效时间',
                prop: 'effectiveDate',
              }, {
                label: '状态',
                prop: 'accountType',
              }, {
                label: '费用类型',
                prop: 'feeTypeName',
              }, {
                label: '渠道费用编码',
                prop: 'channelFeeCode',
              },
              {
                label: '客户类型',
                prop: 'custTypeName',
              },
              {
                label: '是否开票',
                prop: 'isSupplyBillName',
              },
            ],
            rowButtons: {
              items: [{
                type: 'default',
                icon: 'el-icon-delete',
                fn: (row, index) => {
                  this.deleteProduct(index, 1);
                },
              }],
            },
          },
          tableData: [],
          tableButtons: [
            {
              title: '添加费用清单',
              fn: () => {
                this.searchProduct({});
              },
            }, {
              title: '清空',
              fn: () => {
                this.deleteProduct('all', 1);
              },
            },
          ],
        }, {
          title: '发票信息',
          type: 'table',
          tableConfig: {
            disabled: true,
            rows: [
              {
                label: '销货方名称',
                prop: 'sallerName',
              }, {
                label: '销货方纳税人识别号',
                prop: 'sallerTaxNo',
              }, {
                label: '购货方名称',
                prop: 'buyerName',
              }, {
                label: '购货方纳税人识别号',
                prop: 'buyerTaxNo',
              }, {
                label: '发票项目名称',
                prop: 'invoiceItem',
              }, {
                label: '发票代码',
                prop: 'invoiceCode',
              }, {
                label: '发票号码',
                prop: 'invoiceNo',
              }, {
                label: '价税合计(元)',
                prop: 'totalAmount',
              }, {
                label: '不含税总金额(元)',
                prop: 'amount',
              }, {
                label: '总税额',
                prop: 'taxAmount',
              }, {
                label: '税率（%）',
                prop: 'taxRate',
              }, {
                label: '发票流水号',
                prop: 'serialNo',
              }, {
                label: '发票日期',
                prop: 'invoiceDate',
              },
            ],
          },
          tableData: [],
        },
      ],

      //  ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [],
      tableConfigProduct: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '渠道费用编码',
            prop: 'channelFeeCode',
          }, {
            label: '渠道费用名称',
            prop: 'channelFeeName',
          }, {
            label: '金额',
            prop: 'channelCash',
          }, {
            label: '商品名称',
            prop: 'productName',
          }, {
            label: '数量',
            prop: 'channelNum',
          }, {
            label: '生效时间',
            prop: 'effectiveDate',
          }, {
            label: '状态',
            prop: 'accountType',
          }, {
            label: '费用类型',
            prop: 'feeTypeName',
          }, {
            label: '创建时间',
            prop: 'createDateAll',
          },
          {
            label: '是否开票',
            prop: 'isSupplyBillName',
          },
        ],
      }, // 列表配置
      searchConfigProduct: {
        options: [
          {
            valueName: 'channelFeeName',
            placeholder: '请输入渠道费用名称',
          }, {
            valueName: 'productName',
            placeholder: '请输入商品名称',
          },
        ],
      },

      //  ----------------------- 弹窗 - 客户列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户编码',
            prop: 'customerCode',
          },
        ],
      },
      tablePaginationCustomer: {},
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          }, {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
        ],
      },
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },

  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },

  methods: {

    initPageState() {
      if (this.code === 'view') {
        this.setFormItemValue(['productCode'], 'show', true);
      } else {
        this.setFormItemValue(['productCode'], 'show', false);
      }
      if (this.code !== 'add') { // 非新增查询详情
        this.searchDetail();
      }
      if (this.code === 'add') {
        this.drawerConfig[2].show = false;
      }
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.drawerConfig[0].formConfig.disabled = state;
      this.drawerConfig[1].tableConfig.disabled = state;
      this.drawerConfig[1].tableConfig.rowButtons.show = !state;
      this.drawerButton.show = !state;
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post('/dms/dms/channelfeeapply/queryApply', { id: this.id });
        this.drawerConfig[0].ruleForm = { ...result };
        this.drawerConfig[1].tableData = await this.handleFeeData(result.detailList);
        this.drawerConfig[2].tableData = result.invoiceRespVos ? result.invoiceRespVos : '';
        this.setDisabled(this.code === 'view');
        if (!result.invoiceRespVos || result.invoiceRespVos.length <= 0) {
          this.drawerConfig[2].show = false;
        }
        // eslint-disable-next-line no-unused-expressions
        result.payType === '1' && this.setFormItemValue(['applyCash'], 'show', true);
      }
    },

    // 处理费用清单数据
    async handleFeeData(data) {
      const tempFee = await this.getchannelFeeType();
      data.forEach((re) => {
        const item = re;
        tempFee.forEach((type) => {
          if (item.feeType === type.dictCode) {
            item.feeTypeName = type.dictValue;
          }
        });
        item.isSupplyBillName = item.isSupplyBill === 'Y' ? '是' : '否';
        if (item.custType === 'dealer') {
          item.custTypeName = '经销商';
        } else if (item.custType === 'cultural') {
          item.custTypeName = '文化馆';
        } else if (item.custType === 'exclusive') {
          item.custTypeName = '专卖店/团购商';
        } else if (item.custType === 'groupBuyer') {
          item.custTypeName = '';
        } else {
          item.custTypeName = '';
        }
        return item;
      });
      return data;
    },

    /**
     * 查询客户（经销商）列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.tableLoadingCustomer = true;
      const params = { pageSize: 10, ...requestData, positionCode: this.userInfo.positionCode };
      const { result: { data, count } } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList', params,
      );
      this.tableDataCustomer = data;
      this.tableLoadingCustomer = false;
      this.tablePaginationCustomer.total = count;
      this.showDrawer('drawerShowCustomer');
    },

    /**
     * 查询商品列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData, url) {
      const formData = this.drawerConfig[0].ruleForm;
      if (!formData.controlType) {
        this.$message.error('请先选择控制类型');
        return;
      }
      this.tableLoadingProduct = true;
      const params = {
        ...requestData,
        ...formData,
      };
      const { result } = await this.$http.post('/dms/dms/channelfee/queryApplyListByCondition', params);
      this.tableDataProduct = await this.handleFeeData(result);
      this.tableLoadingProduct = false;
      this.showDrawer('drawerShowProduct');
    },

    // 获取渠道费用类型
    async getchannelFeeType() {
      const { result } = await this.$http.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'channel_fee_type',
      });
      return result;
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      console.log(111);
      if (this.drawerShowCustomer === true && !this.selectMessage()) { // 客户
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
      if (this.drawerShowProduct === true) { // 商品
        this.saveProduct();
        return true;
      }
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'custCode');
      this.setFormData(this.selectTableData[0].customerName, 'custName');
      this.setFormData(this.selectTableData[0].customerType, 'custType');
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存选中的商品
     */
    saveProduct() {
      let tableDataArr = [];
      const tableAllData = this.drawerConfig[1].tableData;
      tableDataArr = this.selectTableData.filter((v) => !tableAllData.find((m) => m.channelFeeCode === v.channelFeeCode));
      const tableData = [...this.drawerConfig[1].tableData, ...tableDataArr];
      this.setDrawerValue('费用清单', 'tableData', tableData);
      this.calculateTotalPrice();
      this.closeDrawer('drawerShowProduct');
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, temporaryFlag) {
      data.detailList = this.drawerConfig[1].tableData;
      data.temporaryFlag = temporaryFlag || 'N'; // 是否暂存
      this.loading = true;
      const url = this.code === 'edit' ? '/dms/dms/channelfeeapply/applyUpdate' : '/dms/dms/channelfeeapply/applySave';
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },
    /**
     * 计算清单总价
     */
    calculateTotalPrice() {
      let amount = 0;
      this.drawerConfig[1].tableData.forEach((i) => {
        amount += i.channelCash;
      });
      this.setFormData(amount, 'applyCash');
    },

    /**
     * 删除商品
     * @param index {number || String}
     * @param configIndex {number}
     */
    deleteProduct(index, configIndex) {
      if (index === 'all') {
        this.drawerConfig[configIndex].tableData = [];
      } else {
        this.drawerConfig[configIndex].tableData.splice(index, 1);
      }
      this.calculateTotalPrice();
    },

    /**
     * 设置表单数据
     * @param data {[<void>]}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {[<void>]}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param keyName {string}
     * @param data {<void>}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button{
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #EBEEF5;
}
.drawer-table {
  padding:20px;
  margin-bottom: 100px;
}
.slot-body{
  display: flex;
  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span{
      flex-shrink: 0;
      margin-right: 5px;
    }
    input{
      width: 150px;
    }
  }
}
</style>
